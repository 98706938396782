import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VscSearch } from 'react-icons/vsc';

import KetcherModal from 'components/KetcherModal';
import ComponentInput from 'components/ComponentInput';
import { ContentContainer, ContentTitle } from 'components/Content';
import NoWarranty from 'components/NoWarranty';

export default function CalculatePure() {
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const modalClick = () => {
    ketcherRef.current.getSmiles().then(smiles => {
      setSearchValue(smiles);
      setShowKetcher(false);
      ketcherRef.current.setMolecule('');
    });
  };

  const onSubmit = e => {
    e.preventDefault();

    const searchQuery = encodeURIComponent(searchValue);
    if (searchValue.length > 1) {
      navigate(`/search/pure/${searchQuery}`);
    }
  };

  return (
    <>
      <ContentContainer>
        <ContentTitle>Search Pure Properties</ContentTitle>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8" onSubmit={onSubmit}>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            <button type="submit" className="h-14 w-14 min-w-10 bg-gray-300 p-2">
              <VscSearch className="h-full w-full" />
            </button>
          </div>
          <div className="mt-2 flex flex-wrap gap-x-2 gap-y-1 text-xl font-light text-gray-700">
            <div>examples &#41;</div>
            <div>CCO</div>
            <div>ethanol</div>
            <div>64-17-5</div>
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => setShowKetcher(true)}
          >
            - Draw Structure
          </button>
        </form>
        <NoWarranty />
      </ContentContainer>

      <KetcherModal open={showKetcher} setOpen={setShowKetcher} handleSubmit={modalClick} ref={ketcherRef} />
    </>
  );
}
