import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronsLeft } from 'react-icons/fi';

import { singleParameters, interactionParameters } from 'data/unifac-paramters';

export default function SearchUNIFACParameters() {
  const listOfGroups = useRef();
  const interatctionParameters = useRef();

  return (
    <div className="content-x content-y">
      <h1 className="mb-8 text-5xl font-semibold">UNIFAC Parameters</h1>

      <div>list of groups and their surface and volume</div>
      <div>list of interaction parameters</div>

      <div className="mt-16">
        <h3 className="text-xl font-semibold">References</h3>
        <a
          href="https://pubs.acs.org/doi/full/10.1021/ie010861w"
          target="_blank"
          className="mb-1 mt-2 flex gap-x-2 pl-4 hover:text-blue-600"
        >
          <div>1.</div>
          <div>
            Kang, Jeong Won, et al. "Estimation of mixture properties from first-and second-order group contributions
            with the UNIFAC model." Industrial & engineering chemistry research 41.13 &#40;2002&#41;: 3260-3273.
          </div>
        </a>
        <a
          href="https://www.sciencedirect.com/science/article/pii/S0378381214007353"
          target="_blank"
          className="flex gap-x-2 pl-4 hover:text-blue-600"
        >
          <div>2.</div>
          <div>
            Kang, Jeong Won, Vladimir Diky, and Michael Frenkel. "New modified UNIFAC parameters using critically
            evaluated phase equilibrium data." Fluid Phase Equilibria 388 &#40;2015&#41;: 128-141.
          </div>
        </a>
      </div>

      <div className="mt-16 w-full max-w-screen-md">
        <h3 className="property-h3">List of Groups</h3>
        <div className="mb-4 grid grid-cols-7 pt-1 font-semibold">
          <div className="col-span-1">Main Group</div>
          <div className="col-span-4">Sub Group</div>
          <div className="col-span-1">Ri</div>
          <div className="col-span-1">Qi</div>
        </div>
        {singleParameters.map(parameter => (
          <div key={parameter.DataId}>
            <div className="grid grid-cols-7">
              <div className="col-span-1">{parameter.MainGroupId}</div>
              <div className="col-span-4">{parameter.SubGroup}</div>
              <div className="col-span-1">{parameter.Ri}</div>
              <div className="col-span-1">{parameter.Qi}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-16 w-full max-w-screen-md">
        <h3 className="property-h3">Interaction Parameters</h3>
        <div className="grid grid-cols-5 pt-1 font-semibold">
          <div>n</div>
          <div>m</div>
          <div>
            a1<sub>n,m</sub>
          </div>
          <div>
            a2<sub>n,m</sub>
          </div>
          <div>
            1000a3<sub>n,m</sub>
          </div>
        </div>

        {interactionParameters.map(parameter => (
          <div key={parameter.DataId}>
            <div className="grid grid-cols-5">
              <div>{parameter.MainGroup1}</div>
              <div>{parameter.MainGroup2}</div>
              <div>{parameter.a1}</div>
              <div>{parameter.a2}</div>
              <div>{parameter['1000a3']}</div>
            </div>
          </div>
        ))}
      </div>
      <Link className="mt-16 flex items-center gap-x-1 text-lg hover:text-blue-600" to="/">
        <FiChevronsLeft />
        <div>Go Back</div>
      </Link>
    </div>
  );
}
