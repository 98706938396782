import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-toastify';

import Show from 'components/Show';
import Spinner from 'components/Spinner';

const AsyncPage = forwardRef(({ query, children, className, loadingClassName, errorClassName }, ref) => {
  useEffect(() => {
    if (query.error?.toast) {
      toast.error(
        <>
          <div className="text-lg">{query.error.message}</div>
          <Show show={query.error.instruction}>
            <div className="text-sm">{query.error.instruction}</div>
          </Show>
        </>,
      );
    }
  }, [query]);

  return (
    <div className={twMerge('flex flex-auto flex-col', className)} ref={ref}>
      {/* Loading Page */}
      <Show
        show={query.isLoading}
        as="div"
        className={twMerge('flex flex-auto items-center justify-center', loadingClassName)}
      >
        <Spinner className="w-32" />
      </Show>

      {/* Error Page */}
      <Show
        show={query.isError}
        as="div"
        className={twMerge('flex h-full flex-auto flex-col items-center justify-center', errorClassName)}
      >
        <div className="text-3xl font-bold text-gray-900">{query.error?.code}</div>
        <div className="mb-8 mt-4 text-2xl text-gray-500">Sorry, error has been occured because of some reasons...</div>
        <Link to={-1} className="rounded-lg bg-slate-500 px-4 py-2 text-lg text-white hover:bg-slate-700">
          Go Back
        </Link>
      </Show>

      {/* Succeeded Page */}
      <Show show={!query.isLoading && !query.isError}>{children}</Show>
    </div>
  );
});

export default AsyncPage;
