import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VscChevronUp, VscChevronDown } from 'react-icons/vsc';
import { Field, Label, Radio, RadioGroup, Checkbox } from '@headlessui/react';

import Show from 'components/Show';
import ComponentInput from 'components/ComponentInput';
import NoWarranty from 'components/NoWarranty';
import KetcherModal from 'components/KetcherModal';
import { ContentContainer, ContentTitle } from 'components/Content';

const systemOptions = ['Isotherm', 'Isobaric'];
const activityCoefficientModels = ['NIST-UNIFAC', 'COSMO-SAC', 'COSMO-SAC-ML'];
const vaporPressureModels = ['Wagner25', 'GCGCN-VP'];

export default function SearchBinaryVLE() {
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [selectedOption, setSelectedOption] = useState(systemOptions[1]);
  const [selectedActivityCoefficentModel, setSelectedActivityCoefficientModel] = useState(activityCoefficientModels[2]);
  const [selectedVaporPressureModel, setSelecetedVaporPressureModel] = useState(vaporPressureModels[1]);
  const [component1, setComponent1] = useState('');
  const [component2, setComponent2] = useState('');
  const [temperature, setTemperature] = useState('');
  const [isComponent1, setIsComponent1] = useState(true);
  const [showOptions, setShowOptions] = useState(false);

  const modalClick = () => {
    if (isComponent1) {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent1(smiles);
        ketcherRef.current.setMolecule('');
        setShowKetcher(false);
      });
    } else {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent2(smiles);
        ketcherRef.current.setMolecule('');
        setShowKetcher(false);
      });
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    if (component1.length >= 1 && component2.length >= 1) {
      const component1Query = encodeURIComponent(component1);
      const component2Query = encodeURIComponent(component2);
      const temperatureQuery = encodeURIComponent(temperature);
      const optionQuery = selectedOption.toLowerCase();
      navigate(
        `result?cmp1=${component1Query}&cmp2=${component2Query}&tmp=${temperatureQuery}&option=${optionQuery}&ac=${selectedActivityCoefficentModel}&vp=${selectedVaporPressureModel}`,
      );
    }
  };

  return (
    <>
      <ContentContainer>
        <ContentTitle>Search Binary VLE data</ContentTitle>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8" onSubmit={onSubmit}>
          <div className="mb-2 text-xl">Component1</div>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl placeholder:text-gray-400"
              value={component1}
              onChange={e => setComponent1(e.target.value)}
              placeholder="insert SMILES format"
            />
          </div>
          <div className="mt-2 flex flex-wrap gap-x-2 gap-y-1 text-xl font-light text-gray-700">
            <div>Examples &#41;</div>
            <div>CCO</div>
            <div>C1CCCCC1</div>
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => {
              setIsComponent1(true);
              setShowKetcher(true);
            }}
          >
            - Draw Structure
          </button>
          <div className="mb-2 mt-6 text-xl">Component2</div>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl placeholder:text-gray-400"
              value={component2}
              onChange={e => setComponent2(e.target.value)}
              placeholder="insert SMILES format"
            />
          </div>
          <div className="mt-2 flex flex-wrap gap-x-2 gap-y-1 text-xl font-light text-gray-700">
            <div>Examples &#41;</div>
            <div>CCO</div>
            <div>C1CCCCC1</div>
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => {
              setIsComponent1(false);
              setShowKetcher(true);
            }}
          >
            - Draw Structure
          </button>
          <Show show={selectedOption === 'Isotherm'}>
            <div className="mb-2 mt-6 text-xl">Temperature</div>
            <div className="flex items-center gap-x-4">
              <ComponentInput
                className="mt-2 h-12 w-full max-w-32 text-xl"
                value={temperature}
                onChange={e => {
                  const onlyNumber = e.target.value.replace(/[^0-9.]/g, '');
                  setTemperature(onlyNumber);
                }}
              />
              <div className="text-2xl">K</div>
            </div>
          </Show>
          <button
            type="submit"
            className="mt-6 w-full rounded-xl bg-slate-400 p-2 text-xl font-semibold text-white hover:bg-slate-500"
          >
            Submit
          </button>
          <button
            type="button"
            className="mt-16 flex items-center text-lg font-semibold hover:text-blue-700"
            onClick={() => setShowOptions(current => !current)}
          >
            <p className="mr-2">Show Options</p>
            {showOptions ? <VscChevronUp className="mt-1" /> : <VscChevronDown className="mt-1" />}
          </button>
          <Show as="div" show={showOptions} className="mt-4 grid grid-cols-1 gap-y-4 pt-2 md:grid-cols-2 md:gap-x-8">
            <div>
              <h3 className="text-xl font-bold">System Options</h3>
              <RadioGroup
                value={selectedOption}
                onChange={setSelectedOption}
                aria-label="System Options"
                className="mt-4 bg-white px-4 py-2"
              >
                {systemOptions.map(systemOption => (
                  <Field key={systemOption} className="flex items-center gap-2 py-2">
                    <Label className="flex-auto text-lg">{systemOption}</Label>
                    <Radio
                      value={systemOption}
                      className="group flex size-5 cursor-pointer items-center justify-center rounded-full border border-black bg-white data-[checked]:bg-black"
                    >
                      <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                    </Radio>
                  </Field>
                ))}
              </RadioGroup>
            </div>
            <div>
              <h3 className="text-xl font-bold">Activity Coefficeint Models</h3>
              <RadioGroup
                value={selectedActivityCoefficentModel}
                onChange={setSelectedActivityCoefficientModel}
                aria-label="System Options"
                className="mt-4 bg-white px-4 py-2"
              >
                {activityCoefficientModels.map(activityCoefficentModel => (
                  <Field key={activityCoefficentModel} className="flex items-center gap-2 py-2">
                    <Label className="flex-auto text-lg">{activityCoefficentModel}</Label>
                    <Radio
                      value={activityCoefficentModel}
                      className="group flex size-5 cursor-pointer items-center justify-center rounded-full border border-black bg-white data-[checked]:bg-black"
                    >
                      <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                    </Radio>
                  </Field>
                ))}
              </RadioGroup>
            </div>
            <div>
              <h3 className="text-xl font-bold">Vapor Pressure Model</h3>
              <RadioGroup
                value={selectedVaporPressureModel}
                onChange={setSelecetedVaporPressureModel}
                aria-label="System Options"
                className="mt-4 bg-white px-4 py-2"
              >
                {vaporPressureModels.map(vaporPressureModel => (
                  <Field key={vaporPressureModel} className="flex items-center gap-2 py-2">
                    <Label className="flex-auto text-lg">{vaporPressureModel}</Label>
                    <Radio
                      value={vaporPressureModel}
                      className="group flex size-5 cursor-pointer items-center justify-center rounded-full border border-black bg-white data-[checked]:bg-black"
                    >
                      <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                    </Radio>
                  </Field>
                ))}
              </RadioGroup>
            </div>
          </Show>
        </form>
        <NoWarranty />
      </ContentContainer>

      <KetcherModal open={showKetcher} setOpen={setShowKetcher} handleSubmit={modalClick} ref={ketcherRef} />
    </>
  );
}
