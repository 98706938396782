import { forwardRef } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

import Modal from 'components/Modal';
import { KetcherEditor } from 'components/Ketcher';

const KetcherModal = forwardRef(({ open, setOpen, handleSubmit }, ref) => {
  return (
    <Modal open={open} setOpen={setOpen} className="w-full bg-white p-2 lg:w-[40rem]">
      <div className="border-2 p-2">
        <VscChromeClose className="mb-2 ml-auto cursor-pointer text-xl" onClick={() => setOpen(false)} />
        <KetcherEditor ref={ref} className="w-full" />
        <div className="ml-auto mt-2 w-fit">
          <button onClick={handleSubmit} className="text-lg font-medium text-gray-400 hover:text-black">
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default KetcherModal;
