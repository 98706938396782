import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VscSearch } from 'react-icons/vsc';

import KetcherModal from 'components/KetcherModal';
import ComponentInput from 'components/ComponentInput';
import { ContentTitle, ContentContainer } from 'components/Content';

export default function CalculatePure() {
  const containerRef = useRef();
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const modalClick = () => {
    ketcherRef.current.getSmiles().then(smiles => {
      setSearchValue(smiles);
      setShowKetcher(false);
      ketcherRef.current.setMolecule('');
    });
  };

  const onSubmit = e => {
    e.preventDefault();

    if (searchValue.length >= 1) {
      const urlQuery = encodeURIComponent(searchValue);
      navigate(urlQuery);
    }
  };

  return (
    <>
      <ContentContainer className="content-x py-12">
        <ContentTitle>Pure Property Calculator</ContentTitle>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8" onSubmit={onSubmit}>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl placeholder:text-gray-400"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder="insert SMILES format"
            />
            <button type="submit" className="h-14 w-14 min-w-10 bg-gray-300 p-2">
              <VscSearch className="h-full w-full" />
            </button>
          </div>
          <div className="mt-2 flex flex-wrap gap-x-2 gap-y-1 font-light text-gray-700 lg:text-xl">
            <div>examples &#41;</div>
            <div>CCO</div>
            <div>C1CCCCC1</div>
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => setShowKetcher(true)}
          >
            - Draw Structure
          </button>
        </form>
        <div className="mt-24 border-4 border-yellow-400 bg-yellow-100 p-4 text-xl">
          We do our best to provide high quality data and try to remove wrong data entry. However,&nbsp;
          <span className="font-semibold">we make no warranties for our data and other calculation programs.</span>
          &nbsp;KDB project team shall not be liable for any damage tha may result from errors or ommisions in the
          database.
        </div>
      </ContentContainer>

      <KetcherModal open={showKetcher} setOpen={setShowKetcher} handleSubmit={modalClick} ref={ketcherRef} />
    </>
  );
}
