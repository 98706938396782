import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import Show from 'components/Show';

export default function LogoKDB({ className, subtitle = true }) {
  return (
    <Link to="/" className={twMerge('block w-fit text-white', className)}>
      <div className="text-5xl font-bold lg:text-6xl">KDB</div>
      <Show show={subtitle}>
        <div className="mt-0.5 font-light">Korea Thermophysical Properties Databank</div>
      </Show>
    </Link>
  );
}
