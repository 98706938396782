import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';

import { useCustomQuery } from 'hooks/query-hooks';
import { roundToSignificantFigures } from 'utils/data-treatment';
import Show from 'components/Show';
import request from 'utils/http-request';
import AsyncPage from 'components/AsyncPage';
import Base64Image from 'components/base/Base64Image';
import NoWarranty from 'components/NoWarranty';

function calculateBinaryVLE(props) {
  const query = useCustomQuery({
    queryKey: ['calculate_binary_VLE', props],
    queryFn: async () => {
      return await request({
        url: '/binary-vle/calculate',
        method: 'GET',
        params: props,
      });
    },
  });

  return query;
}

export default function CalculateBinaryVLEResult() {
  const [searchParams] = useSearchParams();
  const compound1 = searchParams.get('cmp1');
  const compound2 = searchParams.get('cmp2');
  const temperature = searchParams.get('tmp');
  const option = searchParams.get('option');
  const vaporPressureModel = searchParams.get('vp');
  const activityCoefficientModel = searchParams.get('ac');
  const [VLECalculatedResult, setVLECalculatedResult] = useState('');

  const calculatedResults = calculateBinaryVLE({
    compound1: compound1,
    compound2: compound2,
    temperature: temperature,
    option: option,
    vapor_pressure_model: vaporPressureModel,
    activity_coefficient_model: activityCoefficientModel,
  });

  useEffect(() => {
    if (calculatedResults.isSuccess) {
      const tempData = calculatedResults.data?.data.data.x1s.map((x1, index) => ({
        x1: roundToSignificantFigures(x1, 2),
        y1: calculatedResults.data?.data.data.y1s[index],
        variables: calculatedResults.data?.data.data.variables[index],
      }));
      setVLECalculatedResult(tempData);
    }
  }, [calculatedResults.isSuccess]);

  return (
    <AsyncPage query={calculatedResults} className="content-x content-y">
      <div className="mb-4 text-4xl font-semibold">Calculated Results</div>
      <h2 className="property-h2">Calculation Options</h2>
      <h3 className="property-h3">Components</h3>
      <div className="md:flex md:space-x-8">
        <div className="w-full max-w-72">
          <Base64Image
            className="mx-auto aspect-square w-full max-w-64"
            src={calculatedResults.data?.data.images.compound1}
          />
          <h3 className="mx-auto -mt-4 w-fit text-xl font-semibold">Component 1</h3>
          <div className="mx-auto w-fit">{compound1}</div>
        </div>
        <div className="w-full max-w-72">
          <Base64Image
            className="mx-auto aspect-square w-full max-w-64"
            src={calculatedResults.data?.data.images.compound2}
          />
          <h3 className="mx-auto -mt-4 w-fit text-xl font-semibold">Component 2</h3>
          <div className="mx-auto w-fit">{compound2}</div>
        </div>
      </div>

      <h3 className="property-h3">System Option</h3>
      <Show as="p" className="property-p" show={option === 'isobaric'}>
        Isobaric
      </Show>
      <Show as="p" className="property-p" show={option === 'isotherm'}>
        Isotherm
      </Show>
      <h3 className="property-h3">Activity Coefficient Models</h3>
      <p className="property-p">{activityCoefficientModel}</p>
      <h3 className="property-h3">Vapor Pressure Model</h3>
      <p className="property-p">{vaporPressureModel}</p>

      <h2 className="property-h2 pt-8">VLE Phase Calculation</h2>
      <h3 className="property-h3">Phase Diagram</h3>
      <div className="mb-2 mt-4 w-full max-w-[48rem] text-xl font-semibold">
        <Show as="div" show={option === 'isobaric'} className="mx-auto w-fit">
          Isobaric&#40;1atm&#41; VLE
        </Show>
        <Show as="div" show={option === 'isotherm'} className="mx-auto w-fit">
          Isotherm&#40;{temperature} K&#41; VLE
        </Show>
      </div>
      <div className="aspect-square w-full max-w-[48rem] sm:aspect-video">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={VLECalculatedResult} layout="vertical">
            <CartesianGrid strokeDasharray="5 5" />
            <XAxis type="number" domain={[0, 1]} />
            <YAxis dataKey={'variables'} type="number" reversed={true} domain={['auto', 'auto']}>
              <Label
                style={{
                  textAnchor: 'middle',
                  fontSize: '130%',
                  fill: 'black',
                }}
                position={'insideLeft'}
                angle={270}
                value={option === 'isobaric' ? 'Temperature (K)' : 'Pressure (kPa)'}
              />
            </YAxis>
            <Legend />
            <Line type="monotone" dataKey="x1" stroke="#8884d8" name="Dew Line (x1)" dot={false} />
            <Line type="monotone" dataKey="y1" stroke="#82ca9d" name="Bubble Line (y1)" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <h3 className="property-h3 mt-8">Data</h3>
      <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-5">
        <div>
          <div className="text-xl font-semibold">
            x<sub>1</sub>
          </div>
          {calculatedResults.data?.data.data.x1s.map(x1 => (
            <div key={`x1_${x1}`}>{roundToSignificantFigures(x1, 2)}</div>
          ))}
        </div>
        <div>
          <div className="text-xl font-semibold">
            y<sub>1</sub>
          </div>
          {calculatedResults.data?.data.data.y1s.map(y1 => (
            <div key={`y1_${y1}`}>{roundToSignificantFigures(y1, 2)}</div>
          ))}
        </div>
        <div>
          <div className="text-xl font-semibold">{option === 'isobaric' ? 'Temperature (K)' : 'Pressure (kPa)'}</div>
          {calculatedResults.data?.data.data.variables.map(variable => (
            <div key={`P_${variable}`}>{roundToSignificantFigures(variable, 5)}</div>
          ))}
        </div>
        <div>
          <div className="text-xl font-semibold">
            γ<sub>1,2</sub>
          </div>
          {calculatedResults.data?.data.data.gamma1s.map(gamma1 => (
            <div key={`gamma1_${gamma1}`}>{roundToSignificantFigures(gamma1, 4)}</div>
          ))}
        </div>
        <div>
          <div className="text-xl font-semibold">
            γ<sub>2,1</sub>
          </div>
          {calculatedResults.data?.data.data.gamma2s.map(gamma2 => (
            <div key={`gamma2_${gamma2}`}>{roundToSignificantFigures(gamma2, 4)}</div>
          ))}
        </div>
      </div>
      <NoWarranty />
    </AsyncPage>
  );
}
