import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { VscThreeBars } from 'react-icons/vsc';

import { useLocationContext } from 'contexts/LocationContext';
import LogoKDB from 'components/LogoKDB';

const menuContents = [
  {
    name: 'Search',
    childrens: [
      { name: 'Pure property data', to: '/search/pure' },
      { name: 'Binary VLE data', to: '/search/binary-vle' },
      { name: 'UNIFAC Paramters', to: '/search/unifac-parameters' },
    ],
  },
  {
    name: 'Calculate',
    childrens: [
      { name: 'Pure properties', to: '/calculate/pure' },
      { name: 'Binary VLE', to: '/calculate/binary-vle' },
    ],
  },
];

function ResponsiveMenu() {
  const { isKorea } = useLocationContext();

  return (
    <>
      {/* PC SIZE */}
      <div className="content-x flex text-xl max-sm:hidden">
        {menuContents.map(menu => (
          <Menu key={menu.name}>
            <MenuButton className="px-8 py-4 text-xl">{menu.name}</MenuButton>
            <MenuItems anchor="bottom" className="rounded-lg bg-white p-2 font-semibold shadow-xl">
              {menu.childrens.map(menuContent => (
                <MenuItem key={menuContent.to} className="p-4">
                  <Link to={menuContent.to} className="block data-[focus]:bg-slate-100">
                    {menuContent.name}
                  </Link>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        ))}
        <Link className="block px-8 py-4 text-xl" to="/document">
          Document
        </Link>
      </div>

      {/* Mobile Size */}
      <Menu as="div" className="flex justify-end py-1 sm:hidden">
        <MenuButton className="p-2 text-3xl">
          <VscThreeBars />
        </MenuButton>
        <MenuItems anchor="bottom" className="w-full rounded-lg bg-white p-2 font-semibold shadow-xl">
          {menuContents.map(menu => (
            <div key={menu.name}>
              <h3 className="border-b-2 border-black p-2 text-lg font-bold">{menu.name}</h3>
              {menu.childrens.map(menuContent => (
                <MenuItem key={menuContent.to} className="px-2 py-4">
                  <Link className="block border-b data-[focus]:bg-slate-100" to={menuContent.to}>
                    {menuContent.name}
                  </Link>
                </MenuItem>
              ))}
            </div>
          ))}
          <MenuItem className="py-4">
            <Link className="block p-2 text-lg font-bold data-[focus]:bg-slate-100" to="/">
              Document
            </Link>
          </MenuItem>
        </MenuItems>
      </Menu>
    </>
  );
}

export default function Header() {
  return (
    <header>
      <div className="bg-slate-800">
        <div className="content-x py-6">
          <LogoKDB />
        </div>
      </div>
      <div className="bg-slate-200">
        <ResponsiveMenu />
      </div>
    </header>
  );
}
