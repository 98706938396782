import { httpErrorList } from 'utils/http-errors';

function findDefinedError(errorCode) {
  const error = httpErrorList.find(e => e.code === errorCode);

  return (
    error ?? {
      code: 'ERROR_500_undefined_error',
      message: 'Undefined Error occurred',
      instruction: 'Please contact the support staff.',
      toast: true,
    }
  );
}

function queryErrorHandler(query) {
  try {
    const responsed = query.error.response.data;
    const error = findDefinedError(responsed.error_code);

    query.error.toast = error.toast;
    if (error.code) query.error.code = error.code;
    if (error.instruction) query.error.instruction = error.instruction;
    if (error.message) query.error.message = error.message;
  } catch {
    query.error.toast = true;
    query.error.instruction = `Can't connect to the server. `;
  }
}

export { queryErrorHandler };
