import { Outlet } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';

export function MainApp({ children = <Outlet /> }) {
  return (
    <div className="relative flex min-h-screen flex-col">
      <Header />
      <main className="flex flex-auto flex-col">{children}</main>
      <Footer />
    </div>
  );
}
