import { useParams, Link } from 'react-router-dom';
import { ContentContainer, ContentTitle } from 'components/Content';

function SearchContainer() {
  return (
    <Link className="mb-8 flex border-2 p-4" to="/search/pure/result/1">
      <div className="aspect-square h-64 bg-gray-200" />
      <div></div>
    </Link>
  );
}

export default function SearchPureResult() {
  const params = useParams();

  return (
    <ContentContainer>
      <ContentTitle>
        Found 0 Result
        <div className="pt-1 text-xl font-normal">
          for <span className="font-bold">{params.id}</span>
        </div>
      </ContentTitle>
      <SearchContainer />
      <SearchContainer />
    </ContentContainer>
  );
}
