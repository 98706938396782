// Modules
import { Route, Routes, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// Layouts
import { MainApp } from 'layouts/MainApp';

// Pages
import PageNotFound from 'routes/404';
import Test from 'routes/test';

import AboutUs from 'routes/about';
import Contact from 'routes/contact';

// Search
import SearchPure from 'routes/search/pure';
import SearchPureResult from 'routes/search/pure/_id';
import SearchPureResultId from 'routes/search/pure/result';
import SearchBinaryVLE from 'routes/search/binary-VLE';
import SearchBinaryVLEResult from 'routes/search/binary-VLE/result';
import SearchBinaryVLEId from 'routes/search/binary-VLE/_id';
import SearchUNIFACParameters from 'routes/search/NIST-UNIFAC';

// Calculate
import CalculatePure from 'routes/calculate/pure';
import CalculatePureResult from 'routes/calculate/pure/result';
import CalculateBinaryVLE from 'routes/calculate/binary-VLE';
import CalculateBinaryVLEResult from 'routes/calculate/binary-VLE/result';
import CalculateBinarySLE from 'routes/calculate/binary-SLE';

// Documents
import ResearchBooks from 'routes/research/books';
import ResearchJournals from 'routes/research/journals';
import ResearchThesis from 'routes/research/thesis';

export default function Router() {
  return (
    <Routes>
      <Route element={<MainApp />}>
        <Route path="/" element={<Navigate to="/calculate/pure" replace />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/search">
          <Route path="pure" element={<SearchPure />} />
          <Route path="pure/:id" element={<SearchPureResult />} />
          <Route path="pure/result/:id" element={<SearchPureResultId />} />
          <Route path="binary-vle" element={<SearchBinaryVLE />} />
          <Route path="binary-vle/result" element={<SearchBinaryVLEResult />} />
          <Route path="binaray-vle/:id" element={<SearchBinaryVLEId />} />
          <Route path="unifac-parameters" element={<SearchUNIFACParameters />} />
        </Route>
        <Route path="/calculate">
          <Route path="pure" element={<CalculatePure />} />
          <Route path="pure/:id" element={<CalculatePureResult />} />
          <Route path="binary-VLE" element={<CalculateBinaryVLE />} />
          <Route path="binary-VLE/result" element={<CalculateBinaryVLEResult />} />
          <Route path="binary-SLE" element={<CalculateBinarySLE />} />
        </Route>
        {/* <Route path="/research">
          <Route path="books" element={<ResearchBooks />} />
          <Route path="journals" element={<ResearchJournals />} />
          <Route path="thesis" element={<ResearchThesis />} />
        </Route>
        <Route path="/test-page" element={<Test />}></Route> */}
        {/* <Route path="*" element={<PageNotFound />} /> */}
      </Route>
    </Routes>
  );
}
