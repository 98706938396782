const httpErrorList = [
  {
    code: 'ERROR_404_endpoint_not_found',
    message: '요청하신 페이지를 찾을 수 없습니다.',
    instruction: undefined,
    toast: true,
  },
  {
    code: 'ERROR_415_unsupported_molecule',
    message: 'Unsupported Molecule',
    instruction: undefined,
    toast: true,
  },
  {
    code: 'ERROR_415_unsupported_SMILES_format',
    message: 'Unsupported SMILES Format',
    instruction: undefined,
    toast: true,
  },
  {
    code: 'ERROR_415_too_large_molecule',
    message: 'Too Large Molecule',
    instruction: 'Please enter a molecule containing 3 to 25 heavy atoms. ',
    toast: true,
  },
];

export { httpErrorList };
