import { ContentContainer, ContentTitle } from 'components/Content';

export default function AboutUs() {
  return (
    <ContentContainer>
      <h3 className="mb-4 text-4xl font-light">About KDB</h3>
      <p className="text-xl">
        The thermodynamic property data of compounds are essential as fundamental resources for education, research, and
        development in chemical processes, and they play a pivotal role in the practical design and operation of
        value-creating chemical process industries. Since the 1970s, the refining and chemical industries have been
        leading drivers of South Korea's economy as major industries. However, in terms of securing basic data and
        design capabilities, the country remains a step behind advanced nations. It is imperative to continuously build
        a foundational database for the chemical engineering field at a national policy level and to actively apply
        research outcomes developed in universities and research institutes to industrial sites.
      </p>
      <p className="mt-4 text-xl">
        The Thermophysical Properties Data Center at Korea University was established in the mid-1990s with the goal of
        developing a thermophysical property database. This initiative was led by a group of experts in the
        thermophysical property field. Under the name "KDB &#40;Korea Thermophysical Properties Data Bank&#41;," the
        database had began providing web services through the Chemical Engineering Research Information Center. Building
        upon these efforts, the center was designated in 2007 as the "Thermophysical Properties Data Center for Organic
        Compounds" by the National Center for Standard Reference Data &#40;supported by the Korean Agency for Technology
        and Standards under the Ministry of Trade, Industry and Energy&#41;. It has since been responsible for producing
        and distributing reference-standard data in the field of thermophysical properties.
      </p>

      <h3 className="mb-4 mt-12 text-4xl font-light">Data Collection</h3>
      <p className="text-xl">
        The types of compounds and properties widely used in the refining and chemical industries are highly diverse,
        and data on these properties are continuously published in journals. Building a database encompassing all
        thermodynamic properties of compounds is an extensive undertaking that requires ongoing maintenance. Our center
        has been building and maintaining the database through various methods, including the following:
      </p>
      <ol className="mt-4 space-y-2 border-4 bg-gray-100 p-4 text-xl">
        <li className="ml-8 flex space-x-4">
          <div>-</div>
          <div>Thermodynamic property data published in journals</div>
        </li>
        <li className="ml-8 flex space-x-4">
          <div>-</div>
          <div>
            Joint utilization of the database through international collaboration: partnerships with the National
            Institute of Standards and Technology &#40;NIST&#41; in the U.S. and the Technical University of Denmark
          </div>
        </li>
        <li className="ml-8 flex space-x-4">
          <div>-</div>
          <div>
            Estimation of property values using quantum mechanics when experimental data are unavailable: joint research
            with ChemEssen Co., Ltd. and COMOlogic GmbH
          </div>
        </li>
      </ol>

      <h3 className="mb-4 mt-12 text-4xl font-light">Thermodynamic Model Development</h3>
      <p className="text-xl">
        Utilizing a large amount of reliable data enables the development of more accurate thermodynamic models. At our
        center, we not only focus on constructing and evaluating databases but also work on developing thermodynamic
        models that are predictive and based on reliable data.
      </p>
      <p className="mt-4 text-xl">
        In particular, the Group Contribution Method &#40;GCM&#41; requires a significant amount of trustworthy data.
        Through international collaborative research with the U.S. National Institute of Standards and Technology
        &#40;NIST&#41; and the Technical University of Denmark, our center has updated the parameters for the UNIFAC
        Group Contribution Method and published the findings in the following paper.
      </p>
      <div className="mt-4 space-y-2 px-4 text-lg">
        <div className="flex space-x-4">
          <div>-</div>
          <p>
            “A New Method for Evaluation of UNIFAC Interaction Parameters”, Fluid Phase Equilibra, 309, 68-75
            &#40;2011&#41;.
          </p>
        </div>
        <div className="flex space-x-4">
          <div>-</div>
          <p>
            “New Modified UNIFAC Parameters using Critically Evaluated Phase Equilibrium Data”, Fluid Phase Equilibria,
            submitted &#40;2014&#41;.
          </p>
        </div>
      </div>

      <p className="mt-6 text-xl">
        The reliable and extensive data mentioned above are not only critical for developing conventional thermodynamic
        models but also play a key role in advancing other cutting-edge computational methods.
      </p>
      <div className="mt-4 space-y-2 px-4 text-lg">
        <div className="flex space-x-4">
          <div>-</div>
          <p>
            Kang, Sung Shin, Jonghwi Lee, and Jeong Won Kang. "An extended COSMO-SAC method for the prediction of
            carboxylic acid solubility." Fluid Phase Equilibria 521 &#40;2020&#41;: 112673.
          </p>
        </div>
        <div className="flex space-x-4">
          <div>-</div>
          <p>
            Hwang, Sun Yoo, and Jeong Won Kang. "Group Contribution-Based Graph Convolution Network: Pure Property
            Estimation Model." International Journal of Thermophysics 43.9 &#40;2022&#41;: 136.
          </p>
        </div>
        <div className="flex space-x-4">
          <div>-</div>
          <p>
            Ryu, Beom Chan, et al. "Group Contribution Based Graph Convolution Network: Predicting Vapor–Liquid
            Equilibrium with COSMO-SAC-ML." International Journal of Thermophysics 44.4 &#40;2023&#41;: 49.
          </p>
        </div>
      </div>
    </ContentContainer>
  );
}
