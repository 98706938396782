import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VscChromeClose } from 'react-icons/vsc';
import { Field, Label, Radio, RadioGroup } from '@headlessui/react';

import Modal from 'components/Modal';
import { KetcherEditor } from 'components/Ketcher';
import ComponentInput from 'components/ComponentInput';
import NoWarranty from 'components/NoWarranty';

export default function CalculateBinarySLE() {
  const activityCoefficientModels = ['COSMO-SAC', 'COSMO-SAC-ML'];

  const containerRef = useRef();
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [selectedActivityCoefficentModel, setSelectedActivityCoefficentModel] = useState(activityCoefficientModels[0]);
  const [component1, setComponent1] = useState('');
  const [component2, setComponent2] = useState('');
  const [isComponent1, setIsComponent1] = useState(true);

  const modalClick = () => {
    if (isComponent1) {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent1(smiles);
        setShowKetcher(false);
        ketcherRef.current.setMolecule('');
      });
    } else {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent2(smiles);
        setShowKetcher(false);
        ketcherRef.current.setMolecule('');
      });
    }
  };

  const onSubmit = e => {
    e.preventDefault();
  };

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [location]);

  return (
    <>
      <div className="content-x py-12">
        <h1 className="mb-8 text-5xl font-semibold">Binary SLE Calculator</h1>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8" onSubmit={onSubmit}>
          <div className="mb-2 text-xl">Component1</div>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl"
              value={component1}
              onChange={e => setComponent1(e.target.value)}
            />
          </div>
          <div className="mt-2 flex flex-wrap gap-x-2 gap-y-1 text-xl font-light text-gray-700">
            <div>Examples &#41;</div>
            <div>CCO</div>
            <div>C1CCCCC1</div>
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => {
              setIsComponent1(true);
              setShowKetcher(true);
            }}
          >
            - Draw Structure
          </button>
          <div className="mb-2 mt-6 text-xl">Component2</div>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl"
              value={component2}
              onChange={e => setComponent2(e.target.value)}
            />
          </div>
          <div className="mt-2 flex flex-wrap gap-x-2 gap-y-1 text-xl font-light text-gray-700">
            <div>Examples &#41;</div>
            <div>CCO</div>
            <div>C1CCCCC1</div>
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => {
              setIsComponent1(false);
              setShowKetcher(true);
            }}
          >
            - Draw Structure
          </button>
          <button className="mt-6 w-full rounded-xl bg-slate-400 p-2 text-xl font-semibold text-white hover:bg-slate-500">
            Submit
          </button>
          <div className="mt-16 grid grid-cols-1 gap-y-4 pt-2 md:grid-cols-2 md:gap-x-8">
            <div>
              <h3 className="text-xl font-bold">Activity Coefficient</h3>
              <RadioGroup
                value={selectedActivityCoefficentModel}
                onChange={setSelectedActivityCoefficentModel}
                aria-label="System Options"
                className="mt-4 bg-white px-4 py-2"
              >
                {activityCoefficientModels.map(activityCoefficientModel => (
                  <Field key={activityCoefficientModel} className="flex items-center gap-2 py-2">
                    <Label className="flex-auto text-lg">{activityCoefficientModel}</Label>
                    <Radio
                      value={activityCoefficientModel}
                      className="group flex size-5 cursor-pointer items-center justify-center rounded-full border border-black bg-white data-[checked]:bg-black"
                    >
                      <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                    </Radio>
                  </Field>
                ))}
              </RadioGroup>
            </div>
          </div>
        </form>
        <NoWarranty />
      </div>

      <Modal open={showKetcher} setOpen={setShowKetcher} className="m-2 w-full border-2 bg-white p-2 xl:w-[36rem]">
        <VscChromeClose
          className="mb-2 ml-auto cursor-pointer text-xl"
          onClick={() => {
            setShowKetcher(false);
          }}
        />
        <KetcherEditor ref={ketcherRef} className="w-full" />
        <div className="ml-auto mt-2 w-fit">
          <button onClick={modalClick} className="text-lg font-medium text-gray-400 hover:text-black">
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}
